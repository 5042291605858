import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LogoIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon viewBox="0 0 512.477 512.477" {...props}>
      <g>
        <g>
          <path
            d="M500.364,292.311h-46.545V172.456c10.473,13.964,24.436,26.764,40.727,36.073c2.327,1.164,3.491,1.164,5.818,1.164
			c3.491,0,8.145-2.327,10.473-5.818c3.491-5.818,1.164-12.8-4.655-16.291c-32.582-19.782-52.364-50.036-52.364-82.618
			c0-6.982-4.655-11.636-11.636-11.636h-46.545c-6.982,0-11.636,4.655-11.636,11.636c0,58.182-57.018,104.727-128,104.727
			s-128-46.545-128-104.727c0-6.982-4.655-11.636-11.636-11.636H69.818c-6.982,0-11.636,4.655-11.636,11.636v1.164
			c0,32.582-18.618,62.836-52.364,82.618c-5.818,2.327-6.982,9.309-4.655,15.127c2.327,3.491,6.982,5.818,10.473,5.818
			c2.327,0,3.491,0,5.818-1.164c16.291-10.473,30.255-22.109,40.727-36.073v118.691H11.636C5.818,291.147,0,295.802,0,302.784
			s4.655,11.636,11.636,11.636l46.545,1.164v80.291H46.545c-6.982,0-11.636,4.655-11.636,11.636c0,6.982,4.655,11.636,11.636,11.636
			h23.273h46.545h23.273c6.982,0,11.636-4.655,11.636-11.636c0-6.982-4.655-11.636-11.636-11.636H128v-46.545
			c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v46.545H81.455v-91.927V116.602h24.436
			c6.982,65.164,72.145,116.364,150.109,116.364s143.127-51.2,150.109-116.364h24.436v187.345v91.927h-23.273v-46.545
			c0-6.982-4.655-11.636-11.636-11.636c-6.982,0-11.636,4.655-11.636,11.636v46.545h-11.636c-6.982,0-11.636,4.655-11.636,11.636
			c0,6.982,4.655,11.636,11.636,11.636h23.273h46.545h23.273c6.982,0,11.636-4.655,11.636-11.636
			c0-6.982-4.655-11.636-11.636-11.636h-11.636v-80.291h46.545c6.982,0,11.636-4.655,11.636-11.636S507.345,292.311,500.364,292.311
			z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M395.636,186.42c-6.982,0-11.636,4.655-11.636,11.636v94.255h-46.545v-47.709c0-6.982-4.655-11.636-11.636-11.636
			s-11.636,4.655-11.636,11.636v47.709h-46.545v-36.073c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v36.073
			h-46.545v-47.709c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v47.709H128v-94.255
			c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v105.891c0,6.982,4.655,11.636,11.636,11.636h279.273
			c6.982,0,11.636-5.818,11.636-11.636V198.056C407.273,191.075,402.618,186.42,395.636,186.42z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default LogoIcon;
